@tailwind base;

a {
  @apply text-blue-700;
}

a:hover {
  @apply border-b-2 border-blue-700;
}

@tailwind components;

@tailwind utilities;

.content p {
  @apply my-6;
}

.content ul {
  @apply my-6;
}

.label-good{
  background-color: #058ad1;
}

.label-average{
  background-color: #1cb43e;
}

.label-bad {
  background-color: #c88920;
}

.label-very-bad{
  background-color: #cd2a29;
}